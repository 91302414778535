<template>
    <v-container>
        <ComponentUploadSurveyList/>
        <ComponentSurveyExcelList/>
        <ComponentSurveyFeedback/>
        
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ComponentUploadSurveyList from '../../components/survey/ComponentUploadSurveyList.vue';
import ComponentSurveyExcelList from '../../components/survey/ComponentSurveyExcelList.vue';
import ComponentSurveyFeedback from '../../components/survey/ComponentSurveyFeedback.vue';
export default {
    components:{
    ComponentUploadSurveyList,
    ComponentSurveyExcelList,
    ComponentSurveyFeedback
},
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        data:null,
        dataHeader:[
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = () => {
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchFailedCouponApi = this.fetchFailedCoupon();

            this.$api.fetch(fetchFailedCouponApi);
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
    }
}
</script>