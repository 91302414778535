<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading && isFirstLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table
            v-else
            :items="data.data"
            :lazy="true"
            :loading="api.isLoading"
            :options.sync="options"
            :headers="dataHeader"
            :items-per-page.sync="options.itemsPerPage"
            :server-items-length="data.total"
            :search="search"
            item-key="user_id"
            :sort-desc:sync="options.sortDesc"
            :sort-by.sync="options.sortBy"
            :footer-props="{
                itemsPerPageOptions:[20,40,100],
                showCurrentPage:true,
            }"
            @update:options="onPaginationUpdate"
            class="elevation-1">
            <template
                v-slot:item.index="{item}">
                {{ ((options.page-1) * options.itemsPerPage) + (data.data.indexOf(item)+1) }}
            </template>
            <template
                v-slot:top>
                <v-toolbar
                    flat 
                    class="text-h4 pt-4"
                    height="auto">
                        <v-row no-gutters>
                            <v-col class="mt-3 mx-3" >
                                <v-row class="mb-3">
                                    <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                        List of survey from excel
                                    </v-toolbar-title>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-btn color="primary" @click="fetch()"  class="mx-3 mt-1">
                                        Refresh
                                    </v-btn> 
                                    <!-- <JsonCSV :data="data" class="mx-2 ">
                                        <v-btn color="primary">
                                            Download
                                        </v-btn>
                                    </JsonCSV> -->

                                </v-row>
                                <v-row>
                                        <v-text-field
                                        v-model="search"
                                        dense
                                        label="Search"
                                        outlined
                                        @input="handleSearchInput"
                                        clearable>
                                    </v-text-field>
                                </v-row>    
                            </v-col>
                        </v-row>
                    </v-toolbar>
            </template>
            <template
                v-slot:item.existing_client="{item}">
                <span
                    v-if="item.existing_client!=null">
                    {{ item.existing_client }}
                </span>
                <span
                    v-else>
                    -
                </span>
            </template>
            <template
                v-slot:item.remark="{item}">
                <span
                    v-if="item.remark!=null">
                    {{ item.remark }}
                </span>
                <span
                    v-else>
                    -
                </span>
            </template>

            <template
                v-slot:item.feedback="{item}">
                <span
                    v-if="item.feedback!=null">
                    {{ item.feedback }}
                </span>
                <span
                    v-else>
                    -
                </span>
            </template>
            <template
                v-slot:item.revenue="{item}">
                <span
                    v-if="item.revenue!=null">
                    {{ item.revenue }}
                </span>
                <span
                    v-else>
                    -
                </span>
            </template>

            <template
                v-slot:item.pat="{item}">
                <span
                    v-if="item.pat!=null">
                    {{ item.pat }}
                </span>
                <span
                    v-else>
                    -
                </span>
            </template>
            <template
                v-slot:item.pbt="{item}">
                <span
                    v-if="item.pbt!=null">
                    {{ item.pbt }}
                </span>
                <span
                    v-else>
                    -
                </span>
            </template>
            <template
                v-slot:item.fye="{item}">
                <span
                    v-if="item.fye!=null">
                    {{ item.fye }}
                </span>
                <span
                    v-else>
                    -
                </span>
            </template>
            <template
                v-slot:item.active="{item}">
                <span
                    v-if="item.active!=null">
                    {{ item.active }}
                </span>
                <span
                    v-else>
                    -
                </span>
            </template>
            
        </v-data-table>
        
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        expanded:[],
        search:"",
        data:null,
        dataHeader:[
         
            {
                text:'#',
                value:'index',
            },
            {
                text:'Company',
                value:'company'
            },
            {
                text:'Excel key',
                value:'excel_key',
            },
            {
                text:'Email',
                value:'email'
            },
            {
                text:'Active',
                value:'active'
            },
            {
                text:'Existing client',
                value:'existing_client',
            },
            {
                text:'Remark',
                value:'remark'
            },
            {
                text:'Special remark',
                value:'special_remark'
            },
            {
                text:'Feedback',
                value:'feedback'
            },
            {
                text:'Revenue excel',
                value:'revenue'
            },
            {
                text:'Revenue database',
                value:'companies_revenue'
            },
            {
                text:'Pat excel',
                value:'pat'
            },
            {
                text:'Pat database',
                value:'companies_pat'
            },
            {
                text:'Pbt excel',
                value:'pbt'
            },
            {
                text:'Pbt database',
                value:'companies_pbt'
            },
            {
                text:'Fye excel',
                value:'fye'
            },
            {
                text:'Fye database',
                value:'companies_fye'
            }
           
        ],
        
        options:{
            itemsPerPage:20,
            page:1,
            sortBy:[],
            search:'',
            couponSearch:'',
        },
        isFirstLoading:true,
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class==="getListOfExcelSurvey") {
                    this.data = resp.data;
                    this.isFirstLoading = false;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchStaffListApi = this.fetchStaffList();
            this.$api.fetch(fetchStaffListApi);
        },
        fetchStaffList() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/developer/excel/survey?page='+this.options.page+"&sortBy="+this.options.sortBy+"&sortDesc="+this.options.sortDesc+"&search="+this.options.search+"&rowsPerPage="+this.options.itemsPerPage;
            return tempApi;
        },
        onPaginationUpdate(pagination){

        if(this.isFirstLoading){
            this.isFirstLoading = false;
        }
        else{
            this.options = this.$_.clone(pagination);
            this.fetch();
        }
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        handleSearchInput() {
            setTimeout(()=>{
                this.options.search = this.search;
            },1000);
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL');
        },
    }
}
</script>